html {
    font-size: 14px;
    height: 100%;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background: #F5F5F5 !important;
    min-height: 100%;
}

a:any-link {
    text-decoration: none;
}

#root {
    min-height: 100%;
}

.highlight:hover {
    background: white;
}

input {
    color: #555555;
    font-size: 1em;
    padding: 0.25em 1em;
    vertical-align: top;
    overflow-wrap: normal;
}

textarea {
    color: #555555;
    font-size: 1em;
    padding: 0.25em 1em;
    vertical-align: top;
}

label {
    color: #555555;
    font-size: 1em;
    padding: 0.25em 1em;
    font-weight: 500;
}

select {
    color: #555555;
    font-size: 1em;
    padding: 0.25em 1em;
}

button:hover {
    background-color: #808080;
    color: white;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

table.RoleList {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
}

tr.RoleListItem {
    border-radius: 2px;
    position: relative;
    background-color: rgb(255, 254, 254);
    margin-bottom: 10px;
    z-index: 0; /* needed for progress bar*/
}

tr.RoleListItem td {
    padding: 6px;
}
.RoleForm input,
.RoleForm select,
.RoleForm textarea {
    display: flex;
    flex-grow: 1;
}

.RoleForm textarea {
    height: 100px;
}

table.RequestList {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 12px;
}

tr.RequestListItem {
    border-radius: 2px;
    position: relative;
    background-color: rgb(255, 254, 254);
    padding: 5px 0;
    margin-bottom: 10px;
    z-index: 0; /* needed for progress bar*/
}

tr.RequestListItem td {
    padding: 4px;
}

.RequestForm input,
.RequestForm select,
.RequestForm textarea {
    display: flex;
    flex-grow: 1;
}

.RequestForm textarea {
    height: 100px;
}
